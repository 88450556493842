import axiosInstance from '@/plugins/axios'
export const getOpsLineList = async () => {
  const res = await axiosInstance.post('/ops-server/opsLine/list')
  return res.data.map((item) => {
    return {
      value: item.lineCode,
      label: item.lineName,
    }
  })
}
export const getOpsTypeList = async (lineCode) => {
  const res = await axiosInstance.post('/ops-server/opsType/listByLine', { lineCode })
  return res.data.map((item) => {
    return {
      value: item.typeCode,
      label: item.typeName,
    }
  })
}
